import axiosInstance from 'axios';

import APP_CONSTANTS from '../Config/AppConstants'

const apiCallStack = []

const Axios = axiosInstance.create({
    baseURL: APP_CONSTANTS.BASE_URL,
    timeout: 30000
})

Axios.interceptors.request.use(async function (config) {
    // Do something before request is sent
  //  const userToken = await localStorage.getItem(APP_CONSTANTS.USER_TOKEN);
    let language = await localStorage.getItem(APP_CONSTANTS.language);

    const backgroundCall = config.data && config.data.background === true
    if (!backgroundCall) {
        apiCallStack.push(config.url)
    }

    let headers = {
        'Content-Type': 'application/json',
        'Accept': '*',
    }

    if (typeof config.headers != 'undefined') {
        headers = config.headers;
    }

    if (!language) {
        language = 'vn'
    }

    headers['language'] = language;
    let userToken ='dsadsadsa';
    if (userToken) {
        headers['Authorization'] = "Bearer " + userToken
    }
    config.headers = headers;
   // config.headers.common = headers
    return config;
}, function (error) {
    return Promise.reject(error);
});

// Add a response interceptor
Axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    const apiCallIndex = apiCallStack.indexOf(response.config.url)
    if (apiCallIndex !== -1) {
        apiCallStack.splice(apiCallIndex, 1)
    }

    return response;

}, async function (error) {
    // console.log(error)
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const apiCallIndex = apiCallStack.indexOf(error.config.url)

    if (apiCallIndex !== -1) {
        apiCallStack.splice(apiCallIndex, 1)
    }

    return await Promise.reject(error);

    //return response;
});

export default Axios
